const API_URL = process.env.VUE_APP_API_URL
const API_VERSION = process.env.VUE_APP_API_VERSION

const API_ROUTES = {
	typeService: {
		get: `${API_URL}/${API_VERSION}/maintenance-types`,
	},
	maintenanceService: {
		get: `${API_URL}/${API_VERSION}/maintenance-services`,
		update: `${API_URL}/${API_VERSION}/maintenance-services`,
	},
	brand: {
		get: `${API_URL}/${API_VERSION}/company/:customer_id/brands`,
	},
	service: {
		get: `${API_URL}/${API_VERSION}/company/brand/:brand_id/service`,
	},
	area: {
		get: `${API_URL}/${API_VERSION}/company/filters/area`,
	},
	locationPatient: {
		get: `${API_URL}/${API_VERSION}/company/filters/sub-area/:area_id`,
	},
	bedBlocking: {
		get: `${API_URL}/${API_VERSION}/bed-request`,
		save: `${API_URL}/${API_VERSION}/bed-request`,
		delete: `${API_URL}/${API_VERSION}/bed-request`,
		update: `${API_URL}/${API_VERSION}/bed-request`,
	},
}

export { API_ROUTES }
